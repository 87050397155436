<template>
  <div>
    <v-form ref="form" enctype="multipart/form-data" lazy-validation>
      <v-text-field
        v-model="version"
        :counter="10"
        label="Version"
        required
      ></v-text-field>

      <v-file-input
        v-model="files"
        color="deep-green accent-4"
        counter
        label="File input"
        multiple
        placeholder="Select your files"
        prepend-icon="mdi-paperclip"
        outlined
        :show-size="1000"
      >
        <template v-slot:selection="{ index, text }">
          <v-chip
            v-if="index < 2"
            color="deep-green accent-4"
            dark
            label
            small
            >{{ text }}</v-chip
          >

          <span
            v-else-if="index === 2"
            class="overline grey--text text--darken-3 mx-2"
            >+{{ files.length - 2 }} File(s)</span
          >
        </template>
      </v-file-input>

      <v-btn color="green" class="mr-4" @click="save">Upload</v-btn>
    </v-form>
  </div>
</template>
<script>
import Api from "@/store/modules/baseApi";
import router from "@/router";

export default {
  data() {
    return {
      version: "",
      files: [],
      url: `https://api.slotbase.co/admin/upload`,
    };
  },
  methods: {
    async initialize() {
      this.version = (await Api.get(this.url)).data.version;
    },
    save() {
      let formData = new FormData();
      formData.append("version", this.version);
      this.files.forEach((element) => {
        formData.append("files", element);
      });
      this.$store.state.progress = true;
      Api.post(this.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .catch(() => {
          this.$store.alertMessage = "Dosya yüklenemedi";
          this.$store.dispatch("setAlert");
        })
        // eslint-disable-next-line no-unused-vars
        .then((x) => {
          this.$store.alertMessage = "Dosyalar yüklendi.";
          this.$store.dispatch("setAlert");
          this.$store.state.progress = false;
          router.push("/");
        });
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>
